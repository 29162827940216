import Experiences from '../../components/experiences/experiences.vue'
import NoWebBanner from '../../components/no-web-banner/no-web-banner.vue'
import GalleryBuilderMem from '../../components/gallery-builder-mem/gallery-builder-mem.vue'
import MiscModal from '../../components/misc-modal/misc-modal.vue'

//custom services
import UserService from '../../services/user';
import OpenService from '../../services/open';
import GlobalService from '../../services/global';

import moment from 'moment';

export default {
  name: 'Member',

  components: {
    Experiences,
    NoWebBanner,
    GalleryBuilderMem,
    MiscModal
  },

  methods: {
    hideModal() {
      this.showModal = false;
      this.$router.replace({ name: 'members'});
    },

    fetchMember() {
      this.loading = this.showModal = this.fetchingUser = this.fetchingReviews = true;
      return UserService
        .getUserProfile(this.id)
        .then(response => {
          let member = response.data;
          this.member = UserService.mapUser(member);
          document.title = this.member.name+"'s Profile - Localites";
          this.loading = this.showModal = false;
        })
        .catch(error => {
          console.log(error);
          this.loading = false;
        }); 
    },

    fetchReportingEnums() {
      return UserService
        .getReportingEnums()
        .then(response => {
          this.enums = response.data.reason;
        })
        .catch(error => {
          console.log(error);
        });
    },

    zoom() {
      window.open('https://zoom.us/download', '_blank');
    },

    skype() {
      window.open('https://www.skype.com/en/get-skype/', '_blank');
    },

    googleHangouts() {
      window.open('https://apps.google.com/meet/', '_blank');
    },

    generateSlug(string) {
      return OpenService.generateSlug(string);
    },

    getUserPhoto(data) {
      return GlobalService.getUserPhoto(data);
    },

    getLazy(text) {
      return GlobalService.getLazy(text);
    },

    formatedTime(string) {
      if (string.length > 6) return moment(string).format('H:mm a');
      return string;
    },

    addFriend() {
      if (this.sendingFriendRequest) return;
      this.sendingFriendRequest = true;
      return UserService
        .addFriend(this.id)
        .then(res => {
          console.log(res);
          this.member.isPending = true;
          this.sendingFriendRequest = false;
        })
        .catch(err => {
          console.log(err);
          this.sendingFriendRequest = false;
        })
    }
  },

  computed: {
    isMe() { return this.$store.state.uuid === this.id }
  },

  mounted() {
    this.showModal = true;
    this.fetchMember(this.id);
  },

  created() {
    if (!this.isMe) this.fetchReportingEnums();
  },

  watch: {
    '$route'(to) {
      if (to.params.id != this.id) {
        this.id = to.params.id;
        window.scrollTo(0,0);
        this.fetchMember();
      }
    },

    active(val) {
      if (val === 'trips' && !this.member.trips) {
        //fetch user trips
        this.fetchingTrips = true;
        UserService.fetchTrips(this.id)
          .then(result => {
            console.log(result);
            this.fetchingTrips = false;
            this.member.trips = result.data.trips;
          })
          .catch(err => {
            console.log(err);
            this.fetchingTrips = false;
          });
      } else if (val === 'friends' && !this.member.friends) {
        //fetch user friends
        this.fetchingFriends = true;
        UserService.fetchFriends(this.id)
          .then(result => {
            console.log(result);
            this.fetchingFriends = false;
            this.member.friends = result.data.friends;
          })
          .catch(err => {
            console.log(err);
            this.fetchingFriends = false;
          });
      } else if (val === 'references' && !this.member.references) {
        //fetch user friends
        this.fetchingReferences = true;
        UserService.fetchReferences(this.id)
          .then(result => {
            console.log(result);
            this.fetchingReferences = false;
            this.member.references = result.data.results;

            if (this.member.references) {
              this.member.totalRatings = 0;
              this.member.references.forEach(x => {
                this.member.totalRatings += x.rating;
              });

              console.log(this.member.totalRatings)

              this.member.averageRating =  Number(this.member.totalRatings/this.member.references.length).toFixed(1);
            }

          })
          .catch(err => {
            console.log(err);
            this.fetchingReferences = false;
          });
      } else if (val === 'itineraries' && !this.member.itineraries) {
        //fetch user friends
        this.fetchingItineraries = true;
        UserService.fetchReferences(this.id)
          .then(result => {
            console.log(result);
            this.fetchingItineraries = false;
            this.member.itineraries = result.data.results;
          })
          .catch(err => {
            console.log(err);
            this.fetchingItineraries = false;
          });
      } else if (val === 'experiences' && !this.member.experiences) {
        //fetch user friends
        this.fetchingExperiences = true;
        UserService.fetchReferences(this.id)
          .then(result => {
            console.log(result);
            this.fetchingExperiences = false;
            this.member.experiences = result.data.results;
          })
          .catch(err => {
            console.log(err);
            this.fetchingExperiences = false;
          });
      }
    }
  },

  data() {

    return {
      loading: false,

      fetchingTrips: false,
      fetchingFriends: false,
      fetchingReferences: false,
      fetchingItineraries: false,
      fetchingExperiences: false,

      showModal: false,
      isFirstActive: true,
      active: 'about',

      showReportingModal: false,
      enums: false,

      sendingFriendRequest: false,

      lazy: require("@/assets/imgs/placeholder.png"),

      id: this.$route.params.id || '',
      member: false
    }
  }
}
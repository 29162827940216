<template>
  <section>
    <v-dialog persistent v-model="showModal" max-width="500px">
      <v-progress-linear
        v-if="saving"
        indeterminate
        color="primary"
        class="mb-0"
      ></v-progress-linear>

      <div style="height: 15px;"></div>

      <v-form ref="form" v-model="valid">
        <b-row style="max-width: 450px; margin: auto;">
          <b-col cols="10">
            <h5 v-if="action === 'rejected'">Denying...</h5>
            <h5 v-if="action === 'cancelled'">Cancelling...</h5>
            <h5 v-if="action === 'deactivate-account'">Deactivating...</h5>
            <h5 v-if="action === 'report-user'">Reporting...</h5>
            <small v-if="!success">Please tell us what went wrong.</small>
            <small v-else class="sc">Thank you. We have received your report request.</small>
          </b-col>

          <b-col cols="2" class="text-right vc">
             <i @click="hideModal" class="fa fa-close close-btn"></i>
          </b-col>

          <div style="height: 20px;"></div>

          <b-col cols="12">
            <v-radio-group v-model="selectedReason" :rules="validationRules.basicRule">
              <v-radio
                v-for="n in enums"
                :key="n"
                :label="n"
                :value="n"
              ></v-radio>
            </v-radio-group>
          </b-col>

          <b-col cols="12">
            <v-text-field 
              :placeholder="placeholder"
              :disabled="saving"
              :rules="validationRules.commentRule"
              v-model="comment" 
              rows="3"></v-text-field>
          </b-col>

          <b-col cols="12" class="text-right">
            <v-btn @click="hideModal" :disabled="saving" outlined color="error">Cancel</v-btn>
            <v-btn @click="submit" :disabled="saving" outlined color="primary" class="ml-5">Submit</v-btn>
          </b-col>
        </b-row>
      </v-form>

      <div style="height: 15px;"></div>

    </v-dialog>
  </section>
</template>

<script>
  import config from '../../config';
  import UserService from '../../services/user';

  export default {
    props: [
      'showModal',
      'action',
      'uuid',
      'enums',
      'hasReported'
    ],

    methods: {
      hideModal() {
        this.$emit('update:showModal', false);
      },

      submit() {
        if (!this.$refs.form.validate()) return;

        if (this.action === 'report-user') {
          this.saving = true;
          return UserService
            .reportUser(this.uuid, {reason: this.selectedReason, comment: this.comment})
            .then(() => {
              this.saving = false;
              this.success = true;
              this.$refs.form.reset();
              this.$emit('update:hasReported', true);
              setTimeout(() => { 
                this.success = false; 
                this.$emit('update:showModal', false); 
              }, 2500);
            })
            .catch(err => {
              console.log(err);
              this.saving = false;
            });
        }
      }
    },

    data() {
      return {
        selectedReason: '',
        comment: '',
        valid: false,

        placeholder: config.meta.placeholder,
        saving: false,
        success: false,
        validationRules: {
          commentRule: [
            v => this.selectedReason != 'Other' || 'Please explain'
          ],
          basicRule: [
            v => !!v || 'Please select a reason'
          ]
        }
      }
    }
  }
</script>